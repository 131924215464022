<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <vs-popup classContent="popup-example" title="Modifier la Appro_caisse_recette" :active.sync="popupAppro_caisse_recetteUpdate">

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" icon="" class="mr-2" />
              <span class="font-medium text-lg leading-none">Modifier la Appro_caisse_recette</span>
            </div>

          </div>
        </div>

     <!-- Content Row -->
     <div class="vx-row mb-6">
       <div class="vx-col md:w-1/2 w-full">
        <div class="mt-4">
          <label class="text-sm">Date Opération</label>
          <flat-pickr v-model="DateOp" :config="{ dateFormat: 'd F Y'}" class="w-full" v-validate="'required'" name="DateOp" />
          <span class="text-danger text-sm"  v-show="errors.has('DateOp')">{{ errors.first('DateOp') }}</span>
        </div>
        <vs-divider />
        <div class="mt-4">
            <vs-select autocomplete label="officine" class="ligne-w" v-model="IdOfficine" >
              <vs-select-item v-for="item in officineData" :key="item._id" :value="item._id" :text="item.officine"/>
            </vs-select>
            <span class="text-danger text-sm" v-show="errors.has('officine')">{{ errors.first('officine') }}</span>
        </div>

        <div class="mt-4">
            <vs-select autocomplete label="Versement" class="ligne-w" v-model="IdVerseBanq" >
              <vs-select-item v-for="item in versementbanqueData" :key="item._id" :value="item._id" :text="item.versementbanque"/>
            </vs-select>
            <span class="text-danger text-sm" v-show="errors.has('versementbanque')">{{ errors.first('versementbanque') }}</span>
        </div>

        <vs-input class="w-full mt-4" label="Motif" v-model="Motif" type="text" v-validate="'required|email'" name="Motif" />
        <span class="text-danger text-sm"  v-show="errors.has('Motif')">{{ errors.first('Motif') }}</span>

        <div class="mt-4">
        <vs-input class="w-full mt-4" label="CheminPreuv" v-model="CheminPreuv" v-validate="'required'" name="CheminPreuv" />
        <span class="text-danger text-sm" v-show="errors.has('CheminPreuv')">{{ errors.first('CheminPreuv') }}</span>
        </div>

       </div>

       <div class="vx-col md:w-1/2 w-full">
        <div class="mt-4">
          <label class="text-sm">Heure Opération</label>
          <flat-pickr v-model="HeurOp" :config="{ dateFormat: 'd F Y'}" class="w-full" v-validate="'required'" name="HeurOp" />
          <span class="text-danger text-sm"  v-show="errors.has('HeurOp')">{{ errors.first('HeurOp') }}</span>
        </div>
        <vs-divider />
        <div class="mt-4">
            <vs-select autocomplete label="utilisateur" class="ligne-w" v-model="IdUser" >
              <vs-select-item v-for="item in userData" :key="item._id" :value="item._id" :text="item.loginU"/>
            </vs-select>
            <span class="text-danger text-sm" v-show="errors.has('user')">{{ errors.first('user') }}</span>
        </div>

        <div class="mt-4">
        <vs-input class="w-full mt-4" label="source" v-model="source" v-validate="'required'" name="source" />
        <span class="text-danger text-sm" v-show="errors.has('source')">{{ errors.first('source') }}</span>
        </div>

        <div class="mt-4">
        <vs-input class="w-full mt-4" label="MoyPaiement" v-model="MoyPaiement" v-validate="'required'" name="MoyPaiement" />
        <span class="text-danger text-sm" v-show="errors.has('MoyPaiement')">{{ errors.first('MoyPaiement') }}</span>
        </div>

        <div class="mt-4">
        <vs-input class="w-full mt-4" label="Numéro de Chèque" v-model="NumCheque" v-validate="'required'" name="NumCheque" />
        <span class="text-danger text-sm" v-show="errors.has('NumCheque')">{{ errors.first('NumCheque') }}</span>
        </div>

       </div>
     </div>
        <!--  Montant du fond de l'approvisionement-->
        <vs-divider position="center">
          <feather-icon svgClasses="w-6 h-6" icon="" class="mr-2" />
          <span class="font-medium text-lg leading-none">Montant de l'approvisionement</span>
        </vs-divider>
      <div class="vx-row mb-6">
         <div class="vx-col w-full">
            <vs-input class="w-full"  type="number" label="Montant" v-validate="'required'" name="Montant" v-model="Montant" />
            <span class="text-danger text-sm"  v-show="errors.has('Montant')">{{ errors.first('Montant') }}</span>
         </div>
      </div>
        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="updateAppro_caisse_recette" :disabled="!validateForm">Modifier</vs-button>
            <vs-button color="warning" type="border" class="mb-2" @click="reset_data" popupAppro_caisse_recetteUpdate = "false" >Annuler</vs-button>
          </div>
        </div>

      </vs-popup>
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateAppro_caisse_recetteShowForm" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  data () {
    return {

      IdOfficine: '',
      IdVerseBanq: '',
      IdUser: '',
      DateOp: '',
      HeurOp: '',
      source: '',
      Motif: '',
      MoyPaiement: '',
      Montant: '',
      CheminPreuv: '',
      NumCheque: '',

      popupAppro_caisse_recetteUpdate: false

    }
  },
  computed:{
    validateForm () {
      return !this.errors.any() && this.IdOfficine !== '' & this.IdVerseBanq !== '' & this.IdUser !== '' & this.DateOp !== '' & this.HeurOp !== '' & this.source !== '' & this.Motif !== '' & this.MoyPaiement !== '' & this.Montant !== '' & this.CheminPreuv !== '' & this.NumCheque !== ''
    }
    /*    // les données des combobox
    fonctionData () {
      return this.$store.state.fonction.fonctions
    },
    Appro_caisse_recetteData () {
      return this.$store.state.personnel.personnels
    }*/
  },
  methods: {
    updateAppro_caisse_recetteShowForm () {

      this.IdOfficine = this.params.data.IdOfficine
      this.IdVerseBanq = this.params.data.IdVerseBanq
      this.IdUser = this.params.data.IdUser
      this.DateOp = this.params.data.DateOp
      this.HeurOp = this.params.data.HeurOp
      this.source = this.params.data.source
      this.Motif = this.params.data.Motif
      this.MoyPaiement = this.params.data.MoyPaiement
      this.Montant = this.params.data.Montant
      this.CheminPreuv = this.params.data.CheminPreuv
      this.NumCheque = this.params.data.NumCheque
      this.popupAppro_caisse_recetteUpdate = true
    },
    updateAppro_caisse_recette () {
      const payload = {

        IdOfficine: this.IdOfficine,
        IdVerseBanq: this.IdVerseBanq,
        IdUser: this.IdUser,
        DateOp: this.DateOp,
        HeurOp: this.HeurOp,
        source: this.source,
        Motif: this.Motif,
        MoyPaiement: this.MoyPaiement,
        Montant: this.data.Montant,
        CheminPreuv: this.CheminPreuv,
        NumCheque: this.NumCheque,

        // status: this.status,
        id: this.params.data._id
      }

      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('appro_caisse_recette/updateAppro_caisse_recette', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })
          //this.popupAppro_caisse_recette = false
          this.resete_data()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
          this.popupAppro_caisse_recetteUpdate = false
        })
    },

    reset_data () {


      this.IdOfficine = ''
      this.IdVerseBanq = ''
      this.IdUser = ''
      this.DateOp = ''
      this.HeurOp = ''
      this.source = ''
      this.Motif = ''
      this.MoyPaiement = ''
      this.Montant = ''
      this.CheminPreuv = ''
      this.NumCheque = ''


    },

    editRecord () {
      this.$router.push(`/apps/appro_caisse_recettes/appro_caisse_recette-edit/${  268}`).catch(() => {})
      /*
        Below line will be for actual product
        Currently it's commented due to demo purpose - Above url is for demo purpose

        this.$router.push("/apps/user/user-edit/" + this.params.data.id).catch(() => {})
      */
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmation de suppression',
        text: `Vous allez supprimer l'approvisionement "${this.params.data.Montant}"`,
        accept: this.deleteRecord,
        acceptText: 'Supprimer'
      })
    },
    deleteRecord () {
      /* Below two lines are just for demo purpose */
      // this.showDeleteSuccess()

      /* UnComment below lines for enabling true flow if deleting user */
      this.$store.dispatch('appro_caisse_recette/removeAppro_caisse_recette', this.params.data._id)
        .then(()   => { this.showDeleteSuccess() })
        .catch(err => { console.error(err)       })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'Approvisionement Supprimé',
        text: 'Approvisionement selectionné a bien été supprimé'
      })
    }
  }
}
</script>
