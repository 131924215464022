<template>

  <div id="page-appro_caisse_recette-list">

    <vs-popup classContent="popup-example" title="Ajouter une Approvisinement" :active.sync="popupAppro_caisse_recette">
      <!-- <vx-card title="Nouvel Utilisateur" class="mb-base"> -->
         <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" icon="" class="mr-2" />
              <!-- <span class="font-medium text-lg leading-none">Nouvel Utilisateur</span> -->
            </div>

          </div>
        </div>

     <!-- Content Row -->
     <div class="vx-row mb-6">
       <div class="vx-col md:w-1/2 w-full">
        <div class="mt-4">
          <label class="text-sm">Date Opération</label>
          <flat-pickr v-model="DateOp" :config="{ dateFormat: 'd F Y'}" class="w-full" v-validate="'required'" name="DateOp" />
          <span class="text-danger text-sm"  v-show="errors.has('DateOp')">{{ errors.first('DateOp') }}</span>
        </div>
        <vs-divider />
        <div class="mt-4">
            <vs-select autocomplete label="officine" class="ligne-w" v-model="IdOfficine" >
              <vs-select-item v-for="item in officineData" :key="item._id" :value="item._id" :text="item.officine"/>
            </vs-select>
            <span class="text-danger text-sm" v-show="errors.has('officine')">{{ errors.first('officine') }}</span>
        </div>

        <div class="mt-4">
            <vs-select autocomplete label="Versement" class="ligne-w" v-model="IdVerseBanq" >
              <vs-select-item v-for="item in versementbanqueData" :key="item._id" :value="item._id" :text="item.versementbanque"/>
            </vs-select>
            <span class="text-danger text-sm" v-show="errors.has('versementbanque')">{{ errors.first('versementbanque') }}</span>
        </div>

        <vs-input class="w-full mt-4" label="Motif" v-model="Motif" type="text" v-validate="'required|email'" name="Motif" />
        <span class="text-danger text-sm"  v-show="errors.has('Motif')">{{ errors.first('Motif') }}</span>

        <div class="mt-4">
        <vs-input class="w-full mt-4" label="CheminPreuv" v-model="CheminPreuv" v-validate="'required'" name="CheminPreuv" />
        <span class="text-danger text-sm" v-show="errors.has('CheminPreuv')">{{ errors.first('CheminPreuv') }}</span>
        </div>

       </div>

       <div class="vx-col md:w-1/2 w-full">
        <div class="mt-4">
          <label class="text-sm">Heure Opération</label>
          <flat-pickr v-model="HeurOp" :config="{noCalendar: true, enableTime: true, dateFormat: 'H:i', time_24hr: true }" class="w-full" v-validate="'required'" name="HeurOp" />
          <span class="text-danger text-sm"  v-show="errors.has('HeurOp')">{{ errors.first('HeurOp') }}</span>
        </div>
        <vs-divider />
        <div class="mt-4">
            <vs-select autocomplete label="utilisateur" class="ligne-w" v-model="IdUser" >
              <vs-select-item v-for="item in userData" :key="item._id" :value="item._id" :text="item.loginU"/>
            </vs-select>
            <span class="text-danger text-sm" v-show="errors.has('user')">{{ errors.first('user') }}</span>
        </div>

        <div class="mt-4">
        <vs-input class="w-full mt-4" label="source" v-model="source" v-validate="'required'" name="source" />
        <span class="text-danger text-sm" v-show="errors.has('source')">{{ errors.first('source') }}</span>
        </div>

        <div class="mt-4">
        <vs-input class="w-full mt-4" label="MoyPaiement" v-model="MoyPaiement" v-validate="'required'" name="MoyPaiement" />
        <span class="text-danger text-sm" v-show="errors.has('MoyPaiement')">{{ errors.first('MoyPaiement') }}</span>
        </div>

        <div class="mt-4">
        <vs-input class="w-full mt-4" label="Numéro de Chèque" v-model="NumCheque" v-validate="'required'" name="NumCheque" />
        <span class="text-danger text-sm" v-show="errors.has('NumCheque')">{{ errors.first('NumCheque') }}</span>
        </div>

       </div>
     </div>
        <!--  Montant du fond de l'approvisionement-->
        <vs-divider position="center">
          <feather-icon svgClasses="w-6 h-6" icon="" class="mr-2" />
          <span class="font-medium text-lg leading-none">Montant de l'approvisionement</span>
        </vs-divider>
      <div class="vx-row mb-6">
         <div class="vx-col w-full">
            <vs-input class="w-full"  type="number" label="Montant" v-validate="'required'" name="Montant" v-model="Montant" />
            <span class="text-danger text-sm"  v-show="errors.has('Montant')">{{ errors.first('Montant') }}</span>
         </div>
      </div>

        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="creatAppro_caisse_recette" :disabled="!validateForm">Enregistrer</vs-button>
            <vs-button color="warning" type="border" class="mb-2" @click="reset_data" status = "false" popupAppro_caisse_recetteUpdate = "false" >Annuler</vs-button>
          </div>
        </div>

        <!-- </vx-card> -->
    </vs-popup>

    <div class="vx-card p-6">

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ Appro_caisse_recetteData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : Appro_caisse_recetteData.length }} of {{ Appro_caisse_recetteData.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full">
            {{Appro_caisse_recetteCompleteData +" donnees complete"}}
            <vs-button
              class="btn-primary mr-4 mb-2 sm:mb-0"
              color="primary"
              @click="popupAppro_caisse_recette=true">
              <feather-icon icon="PlusIcon" svgClasses="h-3 w-4" />
              Ajouter un approvisionement
            </vs-button>
          </div>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
          <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

          <!-- ACTION - DROPDOWN -->
          <vs-dropdown vs-trigger-click class="cursor-pointer">

            <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
              <span class="mr-2 leading-none">Actions</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Delete</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Archive</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Print</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>CSV</span>
                </span>
              </vs-dropdown-item>

            </vs-dropdown-menu>
          </vs-dropdown>
      </div>


      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="Appro_caisse_recetteData"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="7"
        v-model="currentPage" />

    </div>
  </div>

</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

// Store Module

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'


export default {
  components: {
    AgGridVue,
    vSelect,
    flatPickr,
    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions
  },
  data () {
    return {

      IdOfficine: '',
      IdVerseBanq: '',
      IdUser: '',
      DateOp: '',
      // HeurOp: '',
      source: '',
      Motif: '',
      MoyPaiement: '',
      Montant: '',
      CheminPreuv: '',
      NumCheque: '',

      popupAppro_caisse_recette : false,
      popupAppro_caisse_recetteUpdate: false,

      searchQuery: '',

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          width: 125,
          filter: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },
        {
          headerName: 'source',
          field: 'Montant',
          filter: true,
          width: 200,
          cellRendererFramework: 'CellRendererLink'
        },
        {
          headerName: 'Motif',
          field: 'Motif',
          filter: true,
          width: 270
        },
        {
          headerName: 'Montant',
          field: 'Montant',
          filter: true,
          width: 270
        },
        {
          headerName: 'DateOp',
          field: 'DateOp',
          filter: true,
          width: 270
        },
        {
          headerName: 'HeurOp',
          field: 'HeurOp',
          filter: true,
          width: 270
        },

        {
          headerName: 'MoyPaiement',
          field: 'MoyPaiement',
          filter: true,
          width: 270
        },

        {
          headerName: 'Satut',
          field: 'status',
          width: 150,
          cellRendererFramework: 'CellRendererStatus'
        },
        {
          headerName: '',
          field: 'transactions',
          width: 150,
          cellRendererFramework: 'CellRendererActions'
        }

      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus,
        CellRendererVerified,
        CellRendererActions
      }
    }
  },
  /*
 watch: {
    roleFilter (obj) {
      this.setColumnFilter('role', obj.value)
    },
    statusFilter (obj) {
      this.setColumnFilter('status', obj.value)
    },
    isVerifiedFilter (obj) {
      const val = obj.value === 'all' ? 'all' : obj.value === 'yes' ? 'true' : 'false'
      this.setColumnFilter('is_verified', val)
    },
    departmentFilter (obj) {
      this.setColumnFilter('department', obj.value)
    }
  },*/
  computed: {
    /* */
    validateForm () {
      return !this.errors.any() && this.IdOfficine !== '' & this.IdVerseBanq !== '' & this.IdUser !== '' & this.DateOp !== '' & this.HeurOp !== '' & this.source !== '' & this.Motif !== '' & this.MoyPaiement !== '' & this.Montant !== '' & this.CheminPreuv !== '' & this.NumCheque !== ''
    },

    Appro_caisse_recetteData () {
      return this.$store.state.appro_caisse_recette.appro_caisse_recettes
    },

    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
    creatAppro_caisse_recette () {
      const payload = {

        IdOfficine: this.IdOfficine,
        IdVerseBanq:  this.IdVerseBanq,
        IdUser: this.IdUser,
        DateOp: this.DateOp,
        HeurOp: this.HeurOp,
        source: this.source,
        Motif: this.Motif,
        MoyPaiement: this.MoyPaiement,
        Montant: this.Montant,
        CheminPreuv: this.CheminPreuv,
        NumCheque: this.NumCheque

        // status: this.status
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('appro_caisse_recette/addAppro_Caisse_Recette', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })
          //this.popupUser = false
          this.reset_data()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
          this.popupAppro_caisse_recette = false
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })
        })
    },
    reset_data () {

      this.IdOfficine = ''
      this.IdVerseBanq = ''
      this.IdUser = ''
      this.DateOp = ''
      //this.HeurOp = ''
      this.source = ''
      this.Motif = ''
      this.MoyPaiement = ''
      this.Montant = ''
      this.CheminPreuv = ''
      this.NumCheque = ''

    },
    updateAppro_caisse_recette () {
      const payload = {

        IdOfficine: this.IdOfficine,
        IdVerseBanq:  this.IdVerseBanq,
        IdUser: this.IdUser,
        DateOp: this.DateOp,
        HeurOp: this.HeurOp,
        source: this.source,
        Motif: this.Motif,
        MoyPaiement: this.MoyPaiement,
        Montant: this.Montant,
        CheminPreuv: this.CheminPreuv,
        NumCheque: this.NumCheque

        // status: this.status
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('appro_caisse_recette/updateAppro_Caisse_Recette', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })
          //this.popupUser = false
          this.reset_data()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
          this.popupAppro_caisse_recette = false
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })
        })
    },
    setColumnFilter (column, val) {
      const filter = this.gridApi.getFilterInstance(column)
      let modelObj = null

      if (val !== 'all') {
        modelObj = { type: 'equals', filter: val }
      }

      filter.setModel(modelObj)
      this.gridApi.onFilterChanged()
    },
    resetColFilters () {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null)
      this.gridApi.onFilterChanged()

      // Reset Filter Options
      this.roleFilter = this.statusFilter = this.isVerifiedFilter = this.departmentFilter = { label: 'All', value: 'all' }

      this.$refs.filterCard.removeRefreshAnimation()
    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    }
    //
    /*
    getAppro_caisse_recetteData () {
      this.$store.dispatch('personnel/fetchPersonnels')
        .then((res) => {
          console.log('Liste des sociétés', res.data.data)
        })
    },
    getAppro_caisse_recetteData () {
      this.$store.dispatch('fonction/fetchFonctions')
        .then((res) => {
          console.log('Liste des Sociétés', res.data.data)
        })
    }
     */
  },

  mounted () {
    this.gridApi = this.gridOptions.api

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    this.$vs.loading({
      type:'sound'
    })
    this.$store.dispatch('appro_caisse_recette/fetchAppro_Caisse_Recettes')
      .then(res => {
        console.log('les appro_caisse_recettes: ', res)
      })
      .finally(() => {
        this.$vs.loading.close()
      })
      .catch(err => { console.error(err) })
    this.getAppro_caisse_recetteData()
    // this.getAppro_caisse_recetteData()
    // console.log("Donnees completes User >> ",this.$store.getters.getUsersPlusPersData)
  }

}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
